export function secToMinS(e) {
    let minutes = ~~(e / 60);
    let extraSeconds = Math.round(e % 60);
    minutes = minutes < 10 ? "0" + minutes : minutes;
    extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
    return `${minutes}:${extraSeconds}`;
}
export function secToHourMinS(e) {
    let hours = ~~(e / 3600);
    let minutes = ~~((e % 3600) / 60);
    let extraSeconds = Math.round(e % 60);
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
    if (hours == 0) return `${minutes}:${extraSeconds}`;
    return `${hours}:${minutes}:${extraSeconds}`;
}
export function secToHourMin(e) {
    let hours = ~~(e / 3600);
    let minutes = ~~((e % 3600) / 60);
    let extraSeconds = Math.round(e % 60);
    // hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 && hours != 0 ? "0" + minutes : minutes;
    extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
    if (hours == 0) {
        if (minutes <= 1) {
            return `${minutes} min`;
        } else {
            return `${minutes} mins`;
        }
    }
    return `${hours}h${minutes}`;
}
