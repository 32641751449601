export default function NavItem({ children, page, forPage, highlighted }) {
    return (
        <div
            className={`flex items-center gap-4 border-l-4 transition-all duration-150 hover:border-primary px-4 py-3 w-full ${
                page == forPage
                    ? "border-primary dark:border-primary text-primary dark:text-primary bg-light-hover dark:bg-[#292E37]"
                    : `border-transparent text-black dark:text-white ${
                          highlighted &&
                          "bg-light-hover dark:bg-primary dark:bg-opacity-15"
                      }`
            } hover:bg-light-hover dark:hover:bg-[#292E37]`}
        >
            {children}
        </div>
    );
}
