// Components
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Loading from "../UserLayout/InterfaceLayout/Loading/Loading";

export default function Layout(props) {
    return (
        <div
            className={`flex h-screen flex-col ${props.className}`}
            style={props.style}
        >
            {!props.withoutLoading && <Loading />}
            {!props.pure && !props.withoutHeader && (
                <>
                    {props.shouldBeLightHeaderMobile ? (
                        <>
                            <div className="lg:hidden block">
                                <Header
                                    currentPage={props.currentPage}
                                    pure={props.pure}
                                    absoluteHeader={props.absoluteHeader}
                                />
                            </div>
                            <div className="lg:block hidden">
                                <Header
                                    currentPage={props.currentPage}
                                    blackHeader={props.blackHeader}
                                    pure={props.pure}
                                    absoluteHeader={props.absoluteHeader}
                                />
                            </div>
                        </>
                    ) : (
                        <Header
                            currentPage={props.currentPage}
                            blackHeader={props.blackHeader}
                            pure={props.pure}
                            absoluteHeader={props.absoluteHeader}
                        />
                    )}
                </>
            )}

            <div className={`flex-grow ${props.className || null}`}>
                {props.children}
            </div>

            {!props.pure && !props.withoutFooter && (
                <Footer withoutMarginTop={props.withoutMarginTop} />
            )}
        </div>
    );
}
